<template>
    <div class="main-nav-container" :style="mainNavVars">
        <ul class="main-nav-content">
            <li class="nav-content-item">
                <router-link to="/" class="main-nav-link active">
                    Home
                </router-link>
            </li>
            <li class="nav-content-item">
                <router-link to="/cv" class="main-nav-link">
                    Curriculum Vitae
                </router-link>
            </li>
            <li class="nav-content-item">
                <router-link to="/projects" class="main-nav-link">
                    Projects
                </router-link>
            </li>
            <li class="nav-content-item">
                <router-link to="/contact" class="main-nav-link">
                    Contact
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script type="text/javascript">
export default {
    data() {
        return {
            navColor: "transparent",
            routeName: "",
        };
    },

    computed: {
        mainNavVars() {
            return {
                "--nav-color": this.navColor,
            };
        },
    },

    methods: {
        updateColor() {
            console.log(this.$route);
            if (this.$route.name == "Home") {
                this.navColor = "transparent";
            } else {
                this.navColor = "#f03a3a";
            }
        },
    },

    watch: {
        $route() {
            this.updateColor();
        },
    },
};
</script>
<style lang="scss">
.main-nav-container {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: var(--nav-height);
    background-color: var(--nav-color);
    transition: background-color 0.25s ease-in-out;
    z-index: 999;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 90% 100%, 10% 100%, 0 90%);
}
.main-nav-content {
    list-style: none;
    width: 100%;
    height: var(--nav-height);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.nav-content-item {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    cursor: pointer;
}
.main-nav-link {
    position: relative;
    text-decoration: none;
    color: #fff;
    z-index: 1000;
    font-size: 20px;
    text-transform: uppercase;
    &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        width: 0;
        height: 2px;
        background-color: #fff;
        left: 0;
        transform-origin: center;
        transition: width 0.15s ease-in-out;
    }
}
.main-nav-link:hover::after {
    width: 100%;
}
.main-nav-link:focus::after {
    width: 100%;
}
</style>
