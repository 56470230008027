<template>
  <div id="app">
    <main-nav></main-nav>
    <router-view />
  </div>
</template>

<script type="text/javascript">
import mainNav from "@/components/main-nav.vue";

export default {
  components: {
    mainNav,
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: "Lato", "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  --nav-height: 100px;
  --theme-red: #f03a3a;
  --theme-gray: #222;
}
</style>
